import React, { useEffect, useState } from 'react'
import '../assets/scss/pages/_podcast.scss'
import PageHeader from '../components/PageHeader'
import PodcastCard from '../components/PodcastCard'
import { fetchPodcasts } from '../services/api'


const PodcastsPage = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [podcasts, setPodcasts] = useState([])
    const [error, setError] = useState("")

    useEffect(() => {

        const fetchData = async () => {
            setIsLoading(true)
            try {
                const response = await fetchPodcasts()
                console.log(response.podcasts)
                if (response.success) {
                    setPodcasts(response.podcasts)
                } else {
                    setError(response.message)
                }
            } catch (error) {
                setError(error.message)
            }
            finally {

                setIsLoading(false)
            }
        }

        fetchData()

    }, [])
    return (
        <div className='podcasts-page'>
            <PageHeader />
            <div className="container">
                <br /><br />
                <h1 className='section-title'>Recent <span>Podcasts</span></h1>
                <br />
                <hr />
                <br />
                <div className="podcasts-list">
                    {
                        isLoading ? 'Loading..' :
                            podcasts && podcasts.length > 0 ?
                                podcasts.map((podcast, index) => (
                                    <PodcastCard podcast={podcast} index={index} fullDescription={true} />
                                )) : 'No Podcasts Available'
                    }
                </div>
            </div>
        </div>
    )
}

export default PodcastsPage
