import { Apple01Icon, Mic01Icon, PlayCircle02Icon, SpotifyIcon, StartUp01Icon, YoutubeIcon } from 'hugeicons-react'
import React, { useState } from 'react'
import { SlicedText } from './SlicedText'
import { Link } from 'react-router-dom'

const PodcastCard = ({ podcast, index }) => {
    const [showPopup, setShowPopup] = useState()
    const [currentPodcast, seCurrentPodcast] = useState({})

    const handlePopupToggle = (podcast) => {
        setShowPopup(!showPopup)
        seCurrentPodcast(podcast)
    }

    return podcast ? (
        <div onClick={() => handlePopupToggle(podcast)} to={podcast.url} target='blank' key={index} className="podcast">
            <div className="image">
                <div className="overlay">
                    <PlayCircle02Icon />
                </div>
                <img className='thumbnail' src={podcast.thumbnail || 'https://res.cloudinary.com/doicslt4f/image/upload/v1740896702/podcasts/u5ohcc3z7boqqrcidslf.jpg'} alt={podcast.title} />
            </div>
            <div className="podcast-content">
                <p className='category'>{podcast.category.name}</p>
                <p className='title'>{podcast.title}</p>
                <p className='description'><SlicedText text={podcast.description} length={30} /></p>

                <div className="metas">
                    <div className="meta author">
                        <div className="icon">
                            <Mic01Icon size={15} />
                        </div>
                        <span>{podcast.host.user.first_name}</span>
                    </div>

                    <div className="meta read-time">
                        <div className="icon">
                            <StartUp01Icon size={15} />
                        </div>
                        <span>{podcast.rating}</span>
                    </div>
                </div>
            </div>
            {showPopup && currentPodcast?.id === podcast.id &&
                <div className="popup">
                    <div className="popup-content">
                        <h3>Listen {podcast.title} on: </h3>
                        <div className="platforms">
                            {podcast?.platforms?.map((platform, index) => (
                                <Link key={index} to={platform.url} target='blank' className='platform'>
                                    {
                                        platform.name === 'Spotify' && <SpotifyIcon color='green' />
                                    }
                                    {
                                        platform.name === 'Apple Podcasts' && <Apple01Icon color='pink' />
                                    }
                                    {
                                        platform.name === 'YouTube Podcasts' && <YoutubeIcon color='red' />
                                    }
                                    {platform.name}
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </div>
    ) : ''
}

export default PodcastCard
