import React, { useEffect, useState } from 'react'
import PageHeader from '../components/PageHeader'
import { useLocation, useParams } from 'react-router-dom'
import { Calendar01Icon, Clock01Icon, UserIcon } from 'hugeicons-react'
import { SlicedText } from '../components/SlicedText'
import { ArticleCard } from '../components/ArticleCard'
import { fetchArticles } from '../services/api'

const ArticlesArchive = () => {
    const location = useLocation()
    const { slug } = useParams()
    const { title, highlight } = location.state || {}
    const [articles, setArticles] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)

            const response = await fetchArticles(slug ? `page=${1}&page_size=${150}&category_slug=${slug}` : `page${1}&page_size=${150}`)
            if (response.success) {
                setArticles(response.articles)
            }
            setIsLoading(false)
        }

        fetchData();
    }, [])

    return (
        <div className='archive-page'>
            <PageHeader />
            <div className="archive-content container">
                <h1 className='section-title'>{title || 'Recent'} <span>{highlight || 'Articles'}</span> </h1>
                <hr />
                <div className="articles-list">
                    {
                        isLoading ? 'Loading..' : articles && articles?.map((article, index) => (
                            <ArticleCard article={article} index={index} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default ArticlesArchive
