import React from 'react'
import { useLocation } from 'react-router-dom'
import PageHeader from '../components/PageHeader'
import SideBar from '../components/SideBar'
import { Calendar01Icon, Clock01Icon, UserIcon } from 'hugeicons-react'
import { SlicedText } from '../components/SlicedText'
import BusinessBlogPost from '../components/DummyBlog'

const ArticleDetailPage = () => {
    const location = useLocation()
    const { article } = location.state || {}
    return (
        <div className='article-detail-page'>
            <PageHeader />

            <div className="home-articles container">
                <div className="detail-article">
                    <div className="category">
                        <span>{article.category}</span>
                    </div>
                    <h2 className='title'>{article.title}</h2>
                    <div className="metas">
                        <div className="meta author">
                            <div className="icon">
                                <UserIcon size={15} />
                            </div>
                            <small>{article.author}</small>
                        </div>
                        <div className="meta date">
                            <div className="icon">
                                <Calendar01Icon size={15} />
                            </div>
                            <small>{article.date}</small>
                        </div>
                        <div className="meta read-time">
                            <div className="icon">
                                <Clock01Icon size={15} />
                            </div>
                            <small>{article.readingTime} <span>To Read</span></small>
                        </div>
                    </div>
                    <img src={article.featured_image} alt="" className="featured-image thumbnail" />
                    <div className="excerpts">
                        <p>{article.description}</p>
                    </div>

                    <div dangerouslySetInnerHTML={{ __html: article.content }} className="content">
                    </div>
                </div>
                <SideBar />
            </div>
        </div>
    )
}

export default ArticleDetailPage
