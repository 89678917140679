import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import './assets/scss/main/main.css'
import ComingBackSoon from './pages/ComingBackSoon';
import ArticlesArchive from './pages/ArticlesArchive';
import SearchResultsPage from './pages/SearchResultsPage';
import ArticleDetailPage from './pages/ArticleDetailPage';
import PodcastsPage from './pages/Podcasts';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ArticlesArchive />} />
        <Route path="/podcasts/" element={<PodcastsPage />} />
        {/* <Route path="/home/" element={<HomePage />} /> */}
        <Route path="/articles/categories/:slug/" element={<ArticlesArchive />} />
        <Route path="/articles/search/results/" element={<SearchResultsPage />} />
        <Route path="/articles/:slug/" element={<ArticleDetailPage />} />
        <Route path="*" element={<ComingBackSoon />} />
      </Routes>
    </Router>
  );
}

export default App;
