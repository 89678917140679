import { Calendar01Icon, Clock01Icon, UserIcon } from "hugeicons-react"
import { SlicedText } from "./SlicedText"
import { Link, useNavigate } from "react-router-dom"
import { formatDate, formatTime } from "../utils/dateTime"

export const ArticleCard = ({ article, index }) => {
    const navigate = useNavigate()
    const handleNavigate = (e, article) => {
        e.preventDefault()
        navigate(`/articles/${article.slug}/`, { state: { article: article } })
    }
    return article ? (
        <Link onClick={e => handleNavigate(e, article)} key={index} className="article">
            <div className="image">
                <img className='thumbnail' src={article.featured_image || '/default-image.png'} alt={article.title} />
            </div>

            <div className="article-content">
                <div className="article-content">
                    <p className='category'>{article.category}</p>
                    <p className='title'>{article.title}</p>
                    <div className="metas">
                        <div className="meta author">
                            <div className="icon">
                                <UserIcon size={15} />
                            </div>
                            <small>{article.author}</small>
                        </div>
                        <div className="meta date">
                            <div className="icon">
                                <Calendar01Icon size={15} />
                            </div>
                            <small>{article.date}</small>
                        </div>
                        <div className="meta read-time">
                            <div className="icon">
                                <Clock01Icon size={15} />
                            </div>
                            <small>{article.readingTime} <span>To Read</span></small>
                        </div>
                    </div>
                    <div className="excerpts">
                        <p><SlicedText text={article.description} length={100} /></p>
                    </div>
                </div>
            </div>
        </Link>
    ) : ''
}


export const ArticleCard2 = ({ article, index }) => {
    const navigate = useNavigate()
    const handleNavigate = (e, article) => {
        e.preventDefault()
        navigate(`/articles/${article.slug}/`, { state: { article: article } })
    }
    return (
        <Link onClick={e => handleNavigate(e, article)} key={index} className="article">
            <p className='category'>{article.category}</p>
            <h3 className='title'><SlicedText text={article.title} length={30} /></h3>
            <img className='thumbnail' src={article.featured_image || '/default-image.png'} alt={article.title} />
            <div className="metas">
                <div className="meta author">
                    <div className="icon">
                        <UserIcon size={15} />
                    </div>
                    <span>{article?.created_by?.first_name} {article?.created_by?.last_name}</span>
                </div>
                <div className="meta date">
                    <div className="icon">
                        <Calendar01Icon size={15} />
                    </div>
                    <span>{formatDate(article?.created_at)}</span>
                </div>
                <div className="meta read-time">
                    <div className="icon">
                        <Clock01Icon size={15} />
                    </div>
                    <span>{formatTime(article?.created_at)}</span>
                </div>
            </div>
            <div className="excerpts">
                <p><SlicedText text={article.excerpt} length={100} /></p>
            </div>
        </Link>
    )
}